<template>
    <CabinetDetails />
</template>
<script>

import CabinetDetails from './CabinetDetails.vue';
export default {
    name: 'CabinetIncidents',
    components: { CabinetDetails }
};
</script>